import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ErrorHandler from '@plugins/errorHandler'
import API from '@plugins/api'

import { getParamsByName } from '@utils'
const PUBLIC_URL_PREFIX = '/marketingCampaign'

export const getCampaign = createAsyncThunk('marketingCampaign/getCampaign', async (slug, thunkAPI) => {
    try {
        const url = `${PUBLIC_URL_PREFIX}/getCampaignSlugDetails/${slug}`
        console.log(url)
        const { data } = await API.get(url)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data)
    }
})

const initialState = {
    loading: false,
    item: null,
    items: [],
    pagination: {
        per_page: 20,
        current: 1,
        total: 1,
    },
    filters: [],
    errors: null,
}

export const marketingCampaignSlice = createSlice({
    name: 'marketingCampaign',
    initialState,
    reducers: {
        updateFilters: (state, action) => {
            state.filters = action.payload
        },
        updatePagination: (state, action) => {
            const { per_page, current } = action.payload
            if (per_page) state.pagination.per_page = per_page
            if (current) state.pagination.current = current
        },
        clearErrors: (state) => {
            state.errors = null
        },
    },
    extraReducers: {
        [getCampaign.pending]: (state) => {
            state.loading = true
            state.errors = null
        },
        [getCampaign.fulfilled]: (state, action) => {
            const { data } = action.payload
            if (data) {
                state.item = data
            } else {
                state.errors = 'Não encontrado'
            }
            state.loading = false
        },
        [getCampaign.rejected]: (state, action) => {
            console.log(action)
            state.errors = ErrorHandler.getApiErrorMessage(action.payload)
            state.loading = false
        },
    },
})

export const { clearErrors, updatePagination, updateFilters } = marketingCampaignSlice.actions

export default marketingCampaignSlice.reducer