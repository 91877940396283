// ** Initial user ability
export const initialAbility = [
  {action: 'read', subject: 'Auth'},
  {action: 'read', subject: 'GrantType0'}
]

export const adminAbility = [
  {action: 'read', subject: 'Auth'},
  {action: 'read', subject: 'GrantType0'},
  {action: 'read', subject: 'GrantType1'},
  {action: 'read', subject: 'GrantType2'},
  {action: 'read', subject: 'GrantType3'}
]

export const _ = undefined
