export default class ErrorHandler {

    static translate(message) {

    }
    
    static getApiErrorMessage(error) {
        if (Array.isArray(error)) { 
            return error   
        }
        if (typeof error === 'string') return error
        if (typeof error === 'object' || !Array.isArray(error)) {
            if (!error) return null
            if (error.message) return error.message
            if (error.error) {
                if (typeof error.error === 'string') return error.error
                if (error.error.message) return error.error.message
                if (Object.keys(error.error).length === 0) return 'Um erro foi encontrado, mas não foi identificado'
            }
            if (Object.keys(error).length === 0 && error.constructor === Object) return 'Um erro foi encontrado, mas não foi identificado'
            if (Array.isArray(error.errors)) {
                const messages = []
                error.errors.map(each => {
                    if (typeof each === 'string') messages.push(each)
                    if (typeof each === 'object' && each.message) messages.push(each.message)
                })
                return messages
            }
            if (Array.isArray(error.error)) {
                const messages = []
                error.error.map(each => {
                    if (typeof each === 'string') messages.push(each)
                    if (typeof each === 'object' && each.message) messages.push(each.message)
                })
                return messages
            }
        }
        return error
    }
    
}