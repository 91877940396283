import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ErrorHandler from "@plugins/errorHandler";
import API from "@plugins/api";

const PUBLIC_URL = "/teams";

export const getAll = createAsyncThunk(
  "team/getAll",
  async (payload, thunkAPI) => {
    try {
      const { page, perPage } = thunkAPI.getState().team;
      const { data } = await API.get(
        `${PUBLIC_URL}?page=${page}&per_page=${perPage}`
      );
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  items: [],
  loading: false,
  errors: null,
  page: 1,
  perPage: 25,
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
      state.errors = null;
    },
    [getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload.data;
    },
    [getAll.rejected]: (state, action) => {
      state.loading = false;
      state.errors = ErrorHandler.getApiErrorMessage(action.payload);
    },
  },
});

// export const { handleLogin, handleLogout } = teamSlice.actions;

export default teamSlice.reducer;
