import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ErrorHandler from '@plugins/errorHandler';
import API from '@plugins/api';

// const PUBLIC_URL = "/notification";
const RESTRICTED_URL = '/restricted/notification';

export const getAll = createAsyncThunk('notification/getAll', async (payload, thunkAPI) => {
    try {
        const { page, perPage } = thunkAPI.getState().notification;
        const { data } = await API.get(`${RESTRICTED_URL}?page=${page}&per_page=${perPage}`);
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const getNotRead = createAsyncThunk('notification/getNotRead', async (payload, thunkAPI) => {
    try {
        const { page, perPage } = thunkAPI.getState().notification;
        const { data } = await API.get(`${RESTRICTED_URL}/notRead?page=${page}&per_page=${perPage}`);
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const setRead = createAsyncThunk('notification/setRead', async ({ id }, thunkAPI) => {
    try {
        const { data } = await API.patch(`${RESTRICTED_URL}/setRead/${id}`);
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const setAllRead = createAsyncThunk('notification/setAllRead', async (payload, thunkAPI) => {
    try {
        const { data } = await API.patch(`${RESTRICTED_URL}/setAllRead`);
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

const initialState = {
    items: [],
    loading: false,
    errors: null,
    page: 1,
    perPage: 25,
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers: {
        [getAll.pending]: (state) => {
            state.loading = true;
            state.errors = null;
            state.items = [];
        },
        [getAll.fulfilled]: (state, action) => {
            const { data } = action.payload;
            state.loading = false;
            state.items = data.data || [];
        },
        [getAll.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [getNotRead.pending]: (state) => {
            state.loading = true;
            state.errors = null;
        },
        [getNotRead.fulfilled]: (state, action) => {
            const { data } = action.payload;
            state.loading = false;
            state.items = data || [];
        },
        [getNotRead.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [setRead.pending]: (state) => {
            state.loading = true;
            state.errors = null;
        },
        [setRead.fulfilled]: (state, action) => {
            const { data } = action.payload;
            state.loading = false;
            const INDEX = state.items.findIndex((e) => e._id === data._id);
            if (INDEX >= 0) {
                state.items[INDEX] = { ...state.items[INDEX], read: true };
            }
        },
        [setRead.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [setAllRead.pending]: (state) => {
            state.loading = true;
            state.errors = null;
        },
        [setAllRead.fulfilled]: (state) => {
            state.items = [];
            state.loading = false;
        },
        [setAllRead.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
    },
});

// export const { handleLogin, handleLogout } = notificationSlice.actions;

export default notificationSlice.reducer;
