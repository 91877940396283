import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ErrorHandler from '@plugins/errorHandler';
import API from '@plugins/api';
import moment from 'moment';
import { getUserData } from '@utils';

export const getSocialLinks = createAsyncThunk('auth/getSocialLinks', async (payload, thunkAPI) => {
    try {
        const { data } = await API.get('/auth/social-login');
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const attemptLogin = createAsyncThunk(
    'auth/attemptLogin',
    async ({ email, password }, thunkAPI) => {
        try {
            const { data } = await API.post('/internal-user/login', {
                email,
                password
            });
            console.log('data', data);
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async ({ fullName, email, password, birthDate, affiliateCode }, thunkAPI) => {
        try {
            await API.post('/internal-user/signup', {
                fullName,
                email,
                password,
                birthDate,
                affiliateCode,
            });
            const { data: authData } = await API.post('/internal-user/login', {
                email,
                password,
            });
            return authData;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getCurrentUser = createAsyncThunk('auth/getCurrentUser', async (payload, thunkAPI) => {
    try {
        const { data } = await API.get('/auth/me');
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const confirmEmail = createAsyncThunk('auth/confirmEmail', async (userId, thunkAPI) => {
    try {
        const { data } = await API.post('/auth/confirmEmail', {
            code: userId,
        });
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const updateProfile = createAsyncThunk(
    'auth/updateProfile',
    async ({ fullName, cpf, zipCode, birthDate, city, state, complement, phone, street }, thunkAPI) => {
        try {
            const { data } = await API.patch('/auth/me', {
                fullName,
                cpf,
                zipCode,
                birthDate,
                city,
                state,
                complement,
                phone,
                street,
            });
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async ({ currentPassword, email, password }, thunkAPI) => {
        try {
            const { data } = await API.post('/auth/change-password', {
                currentPassword,
                email,
                password,
            });
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const uploadDocument = createAsyncThunk('auth/uploadDocument', async (data, thunkAPI) => {
    try {
        const { front, back } = data;
        const formData = new FormData();
        formData.append('front', front);
        formData.append('back', back);
        const { data: responseData } = await API.post('/restricted/users/uploadDocument', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return responseData;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const uploadAvatar = createAsyncThunk('auth/uploadAvatar', async (data, thunkAPI) => {
    try {
        const { file } = data;
        const formData = new FormData();
        formData.append('file', file);
        const { data: responseData } = await API.post('/restricted/users/uploadAvatar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return responseData;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const generate2fa = createAsyncThunk('auth/generate2fa', async (data, thunkAPI) => {
    try {
        const { data } = await API.post('/auth/generate-2fa');
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const bind2fa = createAsyncThunk('auth/bind2fa', async (sixDigitCode, thunkAPI) => {
    try {
        const { data } = await API.post('/auth/bind-2fa', { sixDigitCode });
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const remove2fa = createAsyncThunk('auth/remove2fa', async (sixDigitCode, thunkAPI) => {
    try {
        const { data } = await API.post('/auth/remove-2fa', { sixDigitCode });
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const checkUser2fa = createAsyncThunk(
    'auth/checkUser2fa',
    async (email, thunkAPI) => {
        try {
            const { data } = await API.post('/auth/use-2fa', { email })
            return data
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

export const getMyBets = createAsyncThunk('auth/getMyBets', async (payload, thunkAPI) => {
    try {
        const startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
        const endDate = moment().add(1, 'day').format('YYYY-MM-DD');
        const { page, perPage } = thunkAPI.getState().auth;
        const { data } = await API.get(
            `/restricted/bet/myBets?page=${page}&per_page=${perPage}&startDate=${startDate}&endDate=${endDate}`
        );
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const passwordRecovery = createAsyncThunk('auth/passwordRecovery', async ({ email }, thunkAPI) => {
    try {
        const { data } = await API.post('/internal-user/login/reset', {
            email,
        });
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const changePasswordByToken = createAsyncThunk(
    'auth/changePasswordByToken',
    async ({ email, password, token }, thunkAPI) => {
        try {
            const { data } = await API.post(`/internal-user/login/changeReset`, {
                email,
                password,
                token,
            });
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const initialState = {
    userData: {},
    qrcode2fa: null,
    token: null,
    loading: false,
    errors: null,
    socialLinks: {},
    items: [],
    page: 1,
    perPage: 20,
    confirmed: false,
    recovery: false,
    check: {}
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        getUser: (state, action) => {
            const user = localStorage.getItem('userData');

            if (user) {
                const parsedUser = JSON.parse(user);
                state.userData = parsedUser;
            }
        },
        handleLogout: (state, action) => {
            localStorage.clear();
            state.userData = null;
            state.token = null;
            location.href = '/';
        },
    },
    extraReducers: {
        [confirmEmail.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
            state.confirmed = false;
        },
        [confirmEmail.fulfilled]: (state, action) => {
            state.loading = false;
            state.confirmed = true;
        },
        [confirmEmail.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [registerUser.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
        },
        [registerUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.userData = action.payload.data;
            state.token = action.payload.token;
            localStorage.setItem('token', JSON.stringify(action.payload.token));
            localStorage.setItem('userData', JSON.stringify(action.payload.data));
        },
        [registerUser.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [attemptLogin.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
        },
        [attemptLogin.fulfilled]: (state, action) => {
            console.log('action', action);
            state.loading = false;
            state.userData = action.payload.userData;
            state.token = action.payload.token;
            localStorage.setItem('token', JSON.stringify(action.payload.token));
            localStorage.setItem('userData', JSON.stringify(action.payload.userData));
        },
        [attemptLogin.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [passwordRecovery.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
        },
        [passwordRecovery.fulfilled]: (state, action) => {
            state.loading = false;
            state.recovery = true;
        },
        [passwordRecovery.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [changePasswordByToken.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
        },
        [changePasswordByToken.fulfilled]: (state, action) => {
            state.loading = false;
            state.confirmed = true;
        },
        [changePasswordByToken.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [getSocialLinks.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
        },
        [getSocialLinks.fulfilled]: (state, action) => {
            state.loading = false;
            state.socialLinks = action.payload;
        },
        [getSocialLinks.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [getCurrentUser.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
        },
        [getCurrentUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.userData = action.payload.data;
            localStorage.setItem('userData', JSON.stringify(action.payload.data));
        },
        [getCurrentUser.rejected]: (state, action) => {
            state.loading = false;
            state.errors = true;
        },
        [updateProfile.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
        },
        [updateProfile.fulfilled]: (state, action) => {
            state.loading = false;
            state.userData = action.payload.data;
            localStorage.setItem('userData', JSON.stringify(action.payload.data));
        },
        [updateProfile.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action.payload.data;
        },
        [changePassword.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
        },
        [changePassword.fulfilled]: (state, action) => {
            state.loading = false;
            state.userData = action.payload.data;
            localStorage.setItem('userData', JSON.stringify(action.payload.data));
        },
        [changePassword.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        },
        //[generate2fa.pending]: (state, action) => {
        //    state.loading = true;
        //    state.errors = null;
        //},
        //[generate2fa.fulfilled]: (state, action) => {
        //    state.loading = false;
        //    state.qrcode2fa = action.payload.qrCode;
        //},
        //[generate2fa.rejected]: (state, action) => {
        //    state.loading = false;
        //    state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        //},
        //[bind2fa.pending]: (state, action) => {
        //    state.loading = true;
        //    state.errors = null;
        //},
        //[bind2fa.fulfilled]: (state, action) => {
        //    const newUserData = { ...state.userData, googleAuthenticator: true };
        //    state.loading = false;
        //    state.qrcode2fa = null;
        //    state.userData = newUserData;
        //    localStorage.setItem('userData', JSON.stringify(newUserData));
        //},
        //[bind2fa.rejected]: (state, action) => {
        //    state.loading = false;
        //    state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        //},
        //[remove2fa.pending]: (state, action) => {
        //    state.loading = true;
        //    state.errors = null;
        //},
        //[remove2fa.fulfilled]: (state, action) => {
        //    const newUserData = { ...state.userData, googleAuthenticator: false };
        //    state.loading = false;
        //    state.userData = newUserData;
        //    localStorage.setItem('userData', JSON.stringify(newUserData));
        //},
        //[remove2fa.rejected]: (state, action) => {
        //    state.loading = false;
        //    state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        //},
        //[checkUser2fa.pending]: (state) => {
        //    state.loading = true
        //    state.errors = null
        //},
        //[checkUser2fa.fulfilled]: (state, action) => {
        //    state.loading = false
        //    state.check = action.payload
        //},
        //[checkUser2fa.rejected]: (state, action) => {
        //    state.loading = false
        //    state.errors = ErrorHandler.getApiErrorMessage(action.payload)
        //},
        [getMyBets.pending]: (state) => {
            state.loading = true;
            state.errors = null;
        },
        [getMyBets.fulfilled]: (state, action) => {
            const { data } = action.payload;
            const newItems = [];
            if (data && data.length) {
                data.map((bet) => {
                    const newBet = {
                        ...bet,
                        bet: { ...bet.bet, oddAtm: bet.oddAtm },
                        bets: bet.bet ? [{ ...bet.bet, oddAtm: bet.oddAtm }] : [],
                        playerIdGuesses: [bet.playerIdGuess],
                        teamIdGuesses: [bet.teamIdGuess],
                    };
                    const INDEX = newItems.findIndex((b) => b.multBetId && b.multBetId === newBet.multBetId);
                    if (INDEX >= 0) {
                        console.log('VOU ADICIONAR PARA A MULTIBET', newBet.bet);
                        newItems[INDEX].bets.push(newBet.bet);
                        newItems[INDEX].value = newItems[INDEX].value + newBet.value;
                        newItems[INDEX].oddAtm = newItems[INDEX].oddAtm * newBet.oddAtm;
                        newItems[INDEX].playerIdGuesses = [
                            ...newItems[INDEX].playerIdGuesses,
                            ...newBet.playerIdGuesses,
                        ];
                        newItems[INDEX].teamIdGuesses = [...newItems[INDEX].teamIdGuesses, ...newBet.teamIdGuesses];
                    } else {
                        newItems.push(newBet);
                    }
                });
            }
            state.loading = false;
            state.items = [...newItems];
        },
        [getMyBets.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [uploadDocument.pending]: (state) => {
            state.loading = true;
            state.errors = null;
        },
        [uploadDocument.fulfilled]: (state, action) => {
            state.loading = false;
            console.log('UPLOAD DOCUMENT', action.payload);
            const userData = { ...getUserData() };
            userData.document = action.payload.data.document;
            localStorage.setItem('userData', JSON.stringify(userData));
            state.userData = userData;
        },
        [uploadDocument.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [uploadAvatar.pending]: (state) => {
            state.loading = true;
            state.errors = null;
        },
        [uploadAvatar.fulfilled]: (state, action) => {
            state.loading = false;
            console.log('UPLOAD AVATAR', action.payload);
            const userData = { ...getUserData() };
            userData.avatar = action.payload.data.avatar;
            localStorage.setItem('userData', JSON.stringify(userData));
            state.userData = userData;
        },
        [uploadAvatar.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
    },
});

export const { getUser, handleLogout } = authSlice.actions;

export default authSlice.reducer;
