import { configureStore } from "@reduxjs/toolkit";
import auth from "../slices/auth";
import banner from "../slices/banner";
import breed from "../slices/breed";
import betItem from "../slices/betItem";
import event from "../slices/event";
import layout from "../slices/layout";
import league from "../slices/league";
import marketingCampaign from "../slices/marketingCampaign";
import navbar from "../slices/navbar";
import notification from "../slices/notification";
import player from "../slices/player";
import setting from "../slices/setting";
import team from "../slices/team";
import partner from "../slices/partner";
import dashboard from "../slices/dashboard";
import Reactotron from "../../ReactotronConfig";

// ** Create store
const store = configureStore({
  reducer: {
    auth,
    dashboard,
    banner,
    breed,
    betItem,
    event,
    layout,
    league,
    marketingCampaign,
    navbar,
    notification,
    player,
    setting,
    team,
    partner,
  },
  enhancers: [Reactotron.createEnhancer()],
});

export { store };
