import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    suggestions: [],
    bookmarks: [],
    query: ''
}

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    getBookmarks: (state, action) => {

    },
    updateBookmarked: (state, action) => {

    },
    handleSearchQuery: (state, action) => {
        
    }
  }
})

// export const {  } = navbarSlice.actions

export default navbarSlice.reducer