import * as currency from 'currency.js';

export const BRLFormatter = (value, symbol = true) => {
    return currency(value, {
        symbol: symbol ? 'R$' : '',
        decimal: ',',
        separator: '.',
        precision: 2,
    }).format();
};

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = (date) => {
    const today = new Date();
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
    if (!value) return value;
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value);
    let formatting = { month: 'short', day: 'numeric' };

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' };
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
    if (userRole === 'admin') return '/';
    if (userRole === 'client') return '/access-control';
    return '/login';
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed', // for input hover border-color
    },
});

export const getAuthToken = () => {
    try {
        return localStorage.getItem('token').replace(/\"/g, '');
    } catch (e) {
        return null;
    }
};

/**
 * Busca jogadores da bet
 */
export const getBetPlayers = (bet) => {
    if (!bet.players) return [];
    const BET_PLAYERS = bet.players.map((p) => {
        return {
            id: p.playerId || p._id,
            title: p.nickName,
            odd: p.currentOdd || p.startOdd,
        };
    });
    return BET_PLAYERS;
};

/**
 * Busca equipes da bet
 */
export const getBetTeams = (bet) => {
    if (!bet.players) return [];
    const BET_TEAMS = [];
    bet.players.map((p) => {
        if (BET_TEAMS.findIndex((e) => e.id === p.teamId) < 0) {
            BET_TEAMS.push({
                id: p.teamId || p._id,
                title: p.teamName,
                odd: p.currentOdd || p.startOdd,
            });
        }
    });
    return BET_TEAMS;
};

export const getBetTeamsWithAvatar = (bet, teams) => {
    if (!bet.players) return [];
    const BET_TEAMS = [];
    bet.players.map((p) => {
        const team = teams.find((c) => c.name === p.teamName);
        if (BET_TEAMS.findIndex((e) => e.id === p.teamId) < 0) {
            BET_TEAMS.push({
                id: p.teamId || p._id,
                title: p.teamName,
                odd: p.currentOdd || p.startOdd,
                avatarPath: team?.avatarPath,
            });
        }
    });
    return BET_TEAMS;
};

export function getParamsByName(object, names) {
    if (typeof object !== 'object') return null;
    if (!object) return null;
    const res = {};
    for (let key of Object.keys(object)) {
        if (names.indexOf(key) > -1) {
            res[key] = object[key];
        }
    }
    return res;
}

export function OddFormatter(value) {
    if (isNaN(value)) return null;
    if (typeof value === 'number') {
        return value.toFixed(2);
    }
    if (typeof value === 'string') {
        return parseFloat(value).toFixed(2);
    }
    return null;
}

export function getAttempts() {
    return localStorage.getItem('attemptsDocuments');
}

export function setAttempts(value) {
    localStorage.setItem('attemptsDocuments', value);
}
