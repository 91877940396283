import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import ErrorHandler from '@plugins/errorHandler';
import API from '@plugins/api';

const PUBLIC_URL = '/partners';

export const getPartners = createAsyncThunk('event/getPartners', async (payload, thunkAPI) => {
    try {

        const { data } = await API.get(`${PUBLIC_URL}`);
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

const initialState = {
    partners: [],
    loading: false,
    errors: null,
};

export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {},
    extraReducers: {
        [getPartners.pending]: (state) => {
            state.loading = true;
            state.errors = null;
            state.partners = [];
        },
        [getPartners.fulfilled]: (state, action) => {
            state.loading = false;
            state.partners = action.payload;
        },
        [getPartners.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
    },
});

export default partnerSlice.reducer;
