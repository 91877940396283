import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BETITEM } from '@src/utility/Consts';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    items: [],
    type: 's',
    totalValue: 0,
    hidden: true,
    error: null,
};

function calculateTotalValue(items) {
    let total = 0;
    items.map((item) => {
        total += parseFloat(item.value);
    });
    return total;
}

export const betItemSlice = createSlice({
    name: 'betItem',
    initialState,
    reducers: {
        clear: (state) => {
            state.items = [];
            state.totalValue = 0;
        },
        clearErrors: (state, action) => {
            for (let i = 0; i < state.items.length; i++) {
                state.items[i].error = null;
            }
        },
        setBetType: (state, action) => {
            if (action.payload === 's' || action.payload === 'm') {
                state.type = action.payload;
            }
        },
        setGeneralError: (state, action) => {
            if (typeof action.payload !== 'string' && action.payload !== null) {
                return;
            }
            state.error = action.payload;
        },
        setItemError: (state, action) => {
            const { betHash, error } = action.payload;
            const INDEX = state.items.findIndex((b) => {
                return b.betHash === betHash;
            });
            if (INDEX >= 0) {
                state.items[INDEX].error = error;
            }
        },
        add: (state, action) => {
            if (!(state.type === 'm' && state.items.some((e) => e.item.bet._id === action.payload.bet._id))) {
                if (state.items.findIndex((e) => e.item.id === action.payload.id) < 0) {
                    const newUuid = uuidv4();
                    state.items.push({
                        item: action.payload,
                        status: BETITEM.NEWSTATUS.PENDING,
                        betHash: newUuid,
                        value: 0,
                    });
                    console.log('ADICIONANDO BETITEM', {
                        item: action.payload,
                        status: BETITEM.NEWSTATUS.PENDING,
                        betHash: newUuid,
                        value: 0,
                    });
                }
            }
        },
        setStatus: (state, action) => {
            const { betHash, status } = action.payload;
            const INDEX = state.items.findIndex((b) => {
                return b.betHash === betHash;
            });
            if (INDEX >= 0) {
                state.items[INDEX].status = status;
            }
        },
        setTotalValue: (state, action) => {
            state.totalValue = action.payload;
        },
        setItemValue: (state, action) => {
            if (action.payload.value <= 0) {
                action.payload.value = 0;
            }
            state.items.map((item, i) => {
                if (item.item.id === action.payload.item.id) {
                    state.items[i].value = action.payload.value;
                }
            });
            state.totalValue = calculateTotalValue(state.items);
        },
        remove: (state, action) => {
            state.items = state.items.filter((e) => e.item.id !== action.payload);
            if (state.items.length <= 0) {
                state.hidden = true;
            }
        },
        toggleHidden: (state) => {
            state.hidden = !state.hidden;
        },
        showForm: (state, action) => {
            state.hidden = false;
        },
        hideForm: (state, action) => {
            state.hidden = true;
        },
    },
    extraReducers: {},
});

export const {
    add,
    setItemValue,
    remove,
    toggleHidden,
    showForm,
    hideForm,
    clear,
    setItemError,
    clearErrors,
    setBetType,
    setTotalValue,
    setStatus,
    setGeneralError,
} = betItemSlice.actions;

export default betItemSlice.reducer;
