// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: "PinnPet",
    appLogoImage: require("@src/assets/images/logo/logo_header.png").default,
    appLogoAvatar: require("@src/assets/images/logo/logo.png").default,
  },
  minSlider: {
    age: 0,
    weight: 0,
    radius: 1,
  },
  maxSlider: {
    age: 30,
    weight: 100,
    radius: 100
  },
  species: {
    dog: require("@src/assets/images/species/dog.svg").default,
    cat: require("@src/assets/images/species/cat.svg").default,
    bird: require("@src/assets/images/species/bird.svg").default,
    other: require("@src/assets/images/species/pig.svg").default
  },
  species_colours: {
    dog: '#FF9F43',
    cat: '#4B4B4B',
    bird: '#28C76F',
    other: '#FF738E'
  },
  species_stats_label: {
    dog: 'Cão',
    cat: 'Gato',
    bird: 'Pássaro',
    other: 'Outros'
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: "horizontal", // vertical, horizontal
    contentWidth: "full", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "static", // static , sticky , floating, hidden
      backgroundColor: "white", // BS color options [primary, success, etc]
    },
    footer: {
      type: "static", // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
  },
};

export default themeConfig;
