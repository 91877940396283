export const BET = {
    TYPE: {
        TEAMS: 1,
        PLAYERS: 2,
    },
};

export const BETITEM = {
    NEWSTATUS: {
        PENDING: 1,
        SENDED: 2,
        SUCCESS: 3,
        ERROR: 4,
    },
};

export const EVENT = {
    STATUS: {
        ACTIVE: 1,
        PAUSED: 2,
        FINISHED: 4,
        PENDIND: 5,
    },
};

export const MARKETING_CAMPAIGN = {
    TYPE: {
        GENERIC: 1,
        USER: 2,
    },
    TYPEOFVALUE: {
        PERCENT: 1,
        FIXED_VALUE: 2,
    },
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2,
    },
    TIMEOTHEROPERATIONS: {
        DAYS: 1,
        DEPOSIT: 2,
    },
};

export const USER = {
    TYPES: {
        COMMON: 1,
        AFFILIATE: 2,
        EMPLOYEE: 3,
    },
};

export const TRANSACTION = {
    TYPES: {
        DEPOSIT: 1,
        WITHDRAW: 2,
        BET: 3,
        GAIN: 4,
        WITHDRAW_APPROVED: 5,
        BET_GAIN: 6,
        REINFORCEMENT: 7,
        WITHDRAW_REJECTED: 8,
        LOSS: 9,
        BONUS_PARTNER: 10,
    },
};
