import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ErrorHandler from "@plugins/errorHandler";
import API from "@plugins/api";

const PUBLIC_URL_PREFIX = "/settings";
const WHITELIST_SETTINGS = [
  "depositFee",
  "withdrawalFee",
  "transferenceFee",
  "ourMargin",
  "minimumDeposit",
  "maximumDeposit",
  "minimumWithdrawal",
  "maximumWithdrawal",
  "minimumBet",
  "maximumBet",
];

export const getOne = createAsyncThunk(
  "setting/getOne",
  async (setting, thunkAPI) => {
    try {
      if (WHITELIST_SETTINGS.indexOf(setting) > -1) {
        const { data } = await API.get(`${PUBLIC_URL_PREFIX}/${setting}`);
        return data;
      } else {
        return null;
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  loading: false,
  items: [],
  errors: null,
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getOne.pending]: (state) => {
      state.loading = true;
      state.errors = null;
    },
    [getOne.fulfilled]: (state, action) => {
      if (action.payload && action.payload.data) {
        const { data } = action.payload;
        state.items = state.items.filter((s) => s.name !== data.name);
        state.items.push(data);
      }
      state.loading = false;
    },
    [getOne.rejected]: (state, action) => {
      state.errors = ErrorHandler.getApiErrorMessage(action.payload);
      state.loading = false;
    },
  },
});

export const { clearErrors } = settingSlice.actions;

export default settingSlice.reducer;
