import { Ability } from '@casl/ability'
import { initialAbility, adminAbility } from './initialAbility'
import { getUserData } from '@utils'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
let userData = null
let existingAbility = initialAbility
let a = null
try {
     userData = getUserData()
     existingAbility = (userData && userData.grantType === 3) ? adminAbility : null
     a = new Ability(existingAbility || initialAbility)
} catch (e) {
    a = new Ability(initialAbility)
}

export default a

