import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import ErrorHandler from '@plugins/errorHandler';
import API from '@plugins/api';

const PUBLIC_URL = '/breeds';

export const getBreeds = createAsyncThunk('event/getBreeds', async (payload, thunkAPI) => {
    try {
        const params = new URLSearchParams();
        params.set('species', payload.species);

        const { data } = await API.get(`${PUBLIC_URL}`, {
            params
        });
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

const initialState = {
    breeds: [],
    loading: false,
    errors: null,
};

export const breedSlice = createSlice({
    name: 'breed',
    initialState,
    reducers: {},
    extraReducers: {
        [getBreeds.pending]: (state) => {
            state.loading = true;
            state.errors = null;
            state.breeds = [];
        },
        [getBreeds.fulfilled]: (state, action) => {
            state.loading = false;
            state.breeds = action.payload;
        },
        [getBreeds.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
    },
});

export default breedSlice.reducer;
