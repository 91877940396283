import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import ErrorHandler from '@plugins/errorHandler';
import API from '@plugins/api';

const PUBLIC_URL = '/league';

export const getAll = createAsyncThunk('league/getAll', async (payload, thunkAPI) => {
    try {
        const { page, perPage } = thunkAPI.getState().team;
        const { data } = await API.get(`${PUBLIC_URL}?page=${page}&per_page=${perPage}`);
        console.log('get all leagues', data);
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const getOne = createAsyncThunk('league/getOne', async (leagueId, thunkAPI) => {
    try {
        const { data } = await API.get(`${PUBLIC_URL}/${leagueId}`);
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const getDetails = createAsyncThunk('league/getDetails', async (leagueId, thunkAPI) => {
    try {
        const { data } = await API.get(`${PUBLIC_URL}/details/${leagueId}`);
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const getWithCurrentEvents = createAsyncThunk('league/getWithCurrentEvents', async (payload, thunkAPI) => {
    try {
        const { page, perPage } = thunkAPI.getState().team;
        const { data } = await API.get(`${PUBLIC_URL}/current-events`);
        console.log('- API - leagues/current-events', data.data);
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

const initialState = {
    items: [],
    itemsWithCurrentEvents: [],
    item: null,
    loading: false,
    errors: null,
    page: 1,
    perPage: 25,
};

export const leagueSlice = createSlice({
    name: 'league',
    initialState,
    reducers: {
        updateBetItemInCurrentEvents: (state, action) => {
            console.log('TENTANDO ATUALIZAR BET', current(state.itemsWithCurrentEvents), action.payload);
            state.itemsWithCurrentEvents.map((league, i) => {
                const tmpLeague = { ...league };
                if (tmpLeague.events) {
                    tmpLeague.events.map((event, k) => {
                        if (event.bets) {
                            event.bets.map((bet, l) => {
                                if (bet._id === action.payload._id) {
                                    tmpLeague.events[k].bets[l] = { ...action.payload };
                                    state.itemsWithCurrentEvents[i] = tmpLeague;
                                }
                            });
                        }
                    });
                }
            });
        },
        updateBetItemInLeague: (state, action) => {
            console.log('TENTANDO ATUALIZAR BET', current(state.item), action.payload);
            const tmpLeague = { ...state.item };
            if (tmpLeague.events) {
                tmpLeague.events.map((event, k) => {
                    if (event.bets) {
                        event.bets.map((bet, l) => {
                            if (bet._id === action.payload._id) {
                                tmpLeague.events[k].bets[l] = { ...action.payload };
                                state.item = tmpLeague;
                            }
                        });
                    }
                });
            }
        },
    },
    extraReducers: {
        [getAll.pending]: (state) => {
            state.loading = true;
            state.errors = null;
        },
        [getAll.fulfilled]: (state, action) => {
            state.loading = false;
            state.items = action.payload.data;
        },
        [getAll.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [getOne.pending]: (state) => {
            state.loading = true;
            state.errors = null;
        },
        [getOne.fulfilled]: (state, action) => {
            state.loading = false;
            state.item = action.payload.data;
        },
        [getOne.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [getDetails.pending]: (state) => {
            state.loading = true;
            state.errors = null;
        },
        [getDetails.fulfilled]: (state, action) => {
            state.loading = false;
            state.item = action.payload.data;
        },
        [getDetails.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [getWithCurrentEvents.pending]: (state) => {
            state.loading = true;
            state.errors = null;
        },
        [getWithCurrentEvents.fulfilled]: (state, action) => {
            state.loading = false;
            state.itemsWithCurrentEvents = action.payload.data;
        },
        [getWithCurrentEvents.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
    },
});

export const { updateBetItemInCurrentEvents, updateBetItemInLeague } = leagueSlice.actions;

export default leagueSlice.reducer;
