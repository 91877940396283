import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ErrorHandler from '@plugins/errorHandler';
import API from '@plugins/api';

export const getDashboardData = createAsyncThunk('dashboard/getDashboardData', async (payload, thunkAPI) => {
    try {
        const paramsContent = [];

        if (payload.startDate) {
            paramsContent.push(['startDate', payload.startDate]);
            ['endDate', payload.endDate]
        }

        if (payload.endDate) {
            paramsContent.push(['endDate', payload.endDate]);
        }

        const params = new URLSearchParams(paramsContent);

        const { data } = await API.get('/dashboard', {
            params
        });
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});
export const getLocations = createAsyncThunk('dashboard/getLocations', async (payload, thunkAPI) => {
    try {

        const paramsContent = [];

        Object.keys(payload).map(key => {
            
            const value = payload[key];
            
            if (value) {
                paramsContent.push([key, value]);
            }
        });

        const params = new URLSearchParams(paramsContent);
        const { data } = await API.get('/dashboard/locations', {
            params
        });
        return data;
    } catch (e) {
        console.log('e', e);
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

const initialState = {
    data: null,
    locations: [],
    loading: false,
    errors: null
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        
    },
    extraReducers: {
        [getDashboardData.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
        },
        [getDashboardData.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getDashboardData.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
        [getLocations.pending]: (state, action) => {
            state.loading = true;
            state.errors = null;
        },
        [getLocations.fulfilled]: (state, action) => {
            state.loading = false;
            state.locations = action.payload;
        },
        [getLocations.rejected]: (state, action) => {
            state.loading = false;
            state.errors = ErrorHandler.getApiErrorMessage(action.payload);
        },
    },
});

export default dashboardSlice.reducer;