import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import themeConfig from "@configs/themeConfig";
import API from "@plugins/api";

const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem("menuCollapsed");
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  skin: window.localStorage.getItem('skin') ? JSON.parse(window.localStorage.getItem('skin')) : themeConfig.layout.skin,
  showSplashScreen: false,
  sidebar: {
    leagues: [],
  },
  home: {
    trendingBets: [],
    trendingPlayers: [],
    trendingTeams: [],
  },
  rightbar: {
    videoLink: null,
  },
  navbar: {
    notificationsCount: 0,
  },
};

export const getNotificationsCount = createAsyncThunk(
  "layout/getNotificationsCount",
  async (payload, thunkAPI) => {
    try {
      const { data } = await API.get(
        `/restricted/notification/countNotRead`
      );
      return data.data;
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getLeaguesSidebar = createAsyncThunk(
  "layout/getLeaguesSidebar",
  async (payload, thunkAPI) => {
    try {
      const { data } = await API.get(`/bet/order-by-league-possibilities`);
      return data.data;
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getTrendingPlayers = createAsyncThunk(
  "layout/getTrendingPlayers",
  async (payload, thunkAPI) => {
    try {
      const { data } = await API.get(`/players/trending`);
      return data.data;
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getTrendingTeams = createAsyncThunk(
  "layout/getTrendingTeams",
  async (payload, thunkAPI) => {
    try {
      const { data } = await API.get(`/teams/trending`);
      return data.data;
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getTrendingBets = createAsyncThunk(
  "layout/getTrendingBets",
  async (payload, thunkAPI) => {
    try {
      const { data } = await API.get(`/bet/trending-items`);
      return data.data;
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    handleContentWidth: (state, action) => {
      state.contentWidth = action.payload;
    },
    handleMenuCollapsed: (state, action) => {
      window.localStorage.setItem("menuCollapsed", action.payload);
      state.menuCollapsed = action.payload;
    },
    handleMenuHidden: (state, action) => {
      state.menuHidden = action.payload;
    },
    handleRTL: (state, action) => {
      state.isRTL = action.payload;
    },
    setRightbarVideoLink: (state, action) => {
      state.rightbar.videoLink = action.payload;
    },
    setShowSplashScreen: (state, action) => {
      state.showSplashScreen = !!action.payload;
    },
    changeSkin: (state, action) => {
      state.skin = action.payload;
    }
  },
  extraReducers: {
    [getLeaguesSidebar.pending]: (state, action) => {
      state.sidebar.leagues = [];
    },
    [getLeaguesSidebar.fulfilled]: (state, action) => {
      state.sidebar.leagues = action.payload;
    },
    [getLeaguesSidebar.rejected]: (state, action) => {
      console.log(action.payload);
    },
    [getTrendingPlayers.pending]: (state, action) => {
      state.home.trendingPlayers = [];
    },
    [getTrendingPlayers.fulfilled]: (state, action) => {
      state.home.trendingPlayers = action.payload;
    },
    [getTrendingPlayers.rejected]: (state, action) => {
      console.log(action.payload);
    },
    [getTrendingTeams.pending]: (state, action) => {
      state.home.trendingTeams = [];
    },
    [getTrendingTeams.fulfilled]: (state, action) => {
      state.home.trendingTeams = action.payload;
    },
    [getTrendingTeams.rejected]: (state, action) => {
      console.log(action.payload);
    },
    [getTrendingBets.pending]: (state, action) => {
      state.home.trendingBets = [];
    },
    [getTrendingBets.fulfilled]: (state, action) => {
      state.home.trendingBets = action.payload;
    },
    [getTrendingBets.rejected]: (state, action) => {
      console.log(action.payload);
    },
    [getNotificationsCount.pending]: (state, action) => {},
    [getNotificationsCount.fulfilled]: (state, action) => {
      state.navbar.notificationsCount = action.payload;
    },
    [getNotificationsCount.rejected]: (state, action) => {
      console.log(action.payload);
    },
  },
});

export const {
  handleContentWidth,
  handleMenuCollapsed,
  handleMenuHidden,
  handleRTL,
  setRightbarVideoLink,
  setShowSplashScreen,
  changeSkin,
} = layoutSlice.actions;

export default layoutSlice.reducer;
