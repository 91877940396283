import axios from "axios";
import { handleLogout } from "../redux/slices/auth";
import { store } from '../redux/storeConfig/store';

const baseURL = process.env.REACT_APP_API
  ? `${process.env.REACT_APP_API}`
  : "http://localhost:3000";

const api = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
});

function getToken() {
  return localStorage.getItem("token")
    ? localStorage.getItem("token").replace(/^\"/, "").replace(/\"$/, "")
    : null;
}

function getApiToken() {
  return localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData")).apiToken
  : null;
}

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    const apiToken = getApiToken();
    
    if (token) config.headers.common["Authorization"] = `Bearer ${token}`;
    if (apiToken) config.headers.common["api_token"] = apiToken;
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// ** Add request/response interceptor
api.interceptors.response.use(
  response => response,
  error => {
    // ** const { config, response: { status } } = error
    const { config, response } = error;
    const originalRequest = config;

    const token = getToken();

    if (response?.status === 403) {
      store.dispatch(handleLogout());
    } else if (token && response?.status === 401) {

      store.dispatch(handleLogout());
      //return originalRequest;
      /*
      if (!this.isAlreadyFetchingAccessToken) {
        this.isAlreadyFetchingAccessToken = true
        this.refreshToken().then(r => {
          this.isAlreadyFetchingAccessToken = false

          // ** Update accessToken in localStorage
          this.setToken(r.data.accessToken)
          this.setRefreshToken(r.data.refreshToken)

          this.onAccessTokenFetched(r.data.accessToken)
        })
      }
      const retryOriginalRequest = new Promise(resolve => {
        this.addSubscriber(accessToken => {
          // ** Make sure to assign accessToken according to your response.
          // ** Check: https://pixinvent.ticksy.com/ticket/2413870
          // ** Change Authorization header
          originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          resolve(this.axios(originalRequest))
        })
      })
      return retryOriginalRequest
      */
    }
    return Promise.reject(error)
  }
)

export default api;
